import {FORMAT, FormatAction, GENERATE, GenerateAction} from './actionTypes';

export const processFormat = (language: string, data: string): FormatAction => ({
  payload: {language, data},
  type: FORMAT,
});

export const processGenerate = (uuids: string[]): GenerateAction => ({
  payload: {uuids},
  type: GENERATE,
});
