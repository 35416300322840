import * as React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Alert, Button, ButtonGroup} from 'reactstrap';
import {processGenerate} from '../../../redux/actions';
import Analytics from '../../../utils/analytics';
import constants from '../../../utils/constants';

const _ = require('lodash');
const styles = require('./uuid.module.scss');

function mapStateToProps(state: any): any {
  return {
    uuids: state.uuid.uuids,
  };
}

function mapDispatchToProps(dispatch: any): any {
  return {
    processGenerate: (uuids: string[]) => dispatch(processGenerate(uuids)),
  };
}

function getUuids(version: string, count: number): string[] {
  const uuidGenerator = require('uuid/' + version);
  const uuids: string[] = [];
  for (let i = 0; i < count; i++) {
    uuids.push(uuidGenerator());
  }
  return uuids;
}

enum SourceState {
  Initial,
  Generated,
  Copied,
}

class ConnectedForm extends React.Component<WithTranslation, any> {

  constructor(props: WithTranslation) {
    super(props);
    this.state = {
      count: 1,
      sourceState: SourceState.Initial,
      version: 'v4',
    };
    this.selectAndCopy = this.selectAndCopy.bind(this);
  }

  public render() {
    // @ts-ignore
    const uuids = this.props.uuids.map((uuid: string) => (
      <div className={styles.row}>
        <button tabIndex={0} className={styles.uuid} key={uuid} onClick={this.selectAndCopy}>{uuid}</button>
        <div className={styles.spacer}/>
      </div>));
    const t = this.props.t;

    let message;
    let messageColor;

    switch (this.state.sourceState) {
      case SourceState.Generated:
        message = t(constants.PAGE_UUID + '-uuid-generated');
        messageColor = 'success';
        break;
      case SourceState.Copied:
        message = t(constants.PAGE_UUID + '-uuid-copied', {uuid: this.state.copied});
        messageColor = 'success';
        break;
      case SourceState.Initial:
      default:
        message = t(constants.PAGE_UUID + '-uuid-initial');
        messageColor = 'info';
        break;
    }

    return (
      <div className={styles.generator}>
        <Alert color={messageColor}>
          <span dangerouslySetInnerHTML={{__html: message}}/>
        </Alert>
        <div className={styles.main}>
          <div className={styles.controls}>
            <form className={'container'}>
              <div className={'form-group'}>
                <label>{t(constants.PAGE_UUID + '-version')}</label>
                <ButtonGroup>
                  <Button
                    size="sm"
                    onClick={() => this.setVersion('v1')}
                    active={this.state.version === 'v1'}
                    color="secondary"
                  >
                    v1
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => this.setVersion('v4')}
                    active={this.state.version === 'v4'}
                    color="secondary"
                  >
                    v4
                  </Button>
                </ButtonGroup>
              </div>
              <div className={'form-group'}>
                <label>{t(constants.PAGE_UUID + '-count')}</label>
                <ButtonGroup>
                  <Button
                    size="sm"
                    onClick={() => this.setCount(1)}
                    active={this.state.count === 1}
                    color="secondary"
                  >
                    1
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => this.setCount(10)}
                    active={this.state.count === 10}
                    color="secondary"
                  >
                    10
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => this.setCount(100)}
                    active={this.state.count === 100}
                    color="secondary"
                  >
                    100
                  </Button>
                </ButtonGroup>
              </div>
            </form>
          </div>
          <div className={styles.content}>
            <div className={styles.source}>
              {uuids}
            </div>
          </div>
        </div>
        <p className={styles.disclaimer} dangerouslySetInnerHTML={{__html: t(constants.PAGE_UUID + '-disclaimer')}}/>
      </div>
    );
  }

  public componentDidMount(): void {
    this.generate();
  }

  private generate() {
    const version = this.state.version;
    const count = this.state.count;
    const uuids = getUuids(version, count);
    // @ts-ignore
    this.props.processGenerate(uuids);
    this.setState({sourceState: SourceState.Generated});
    // this is needed in development mode because gtag is initialized
    // after the UUID component is done.
    Analytics.gtag('event', 'generate', this.getGtagEventData());
  }

  private setVersion(version: string) {
    this.setState({version}, () => this.generate());
  }

  private setCount(count: number) {
    this.setState({count}, () => this.generate());
  }

  private selectAndCopy(e: React.MouseEvent<HTMLButtonElement>) {
    const element = e.currentTarget;
    window.getSelection().selectAllChildren(element!);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    const copiedElements = document.querySelectorAll('.' + styles.copied);
    _.each(copiedElements, (c: HTMLButtonElement) => {
      c.classList.remove(styles.copied);
    });
    element.classList.add(styles.copied);

    this.setState({sourceState: SourceState.Copied, copied: element.innerText});

    Analytics.gtag('event', 'select-and-copy', this.getGtagEventData());
  }

  private getGtagEventData() {
    const version = this.state.version;
    const count = this.state.count;
    const currentPage = constants.PAGE_UUID;
    return {
      event_category: currentPage,
      event_label: JSON.stringify({currentPage, version, count}),
    };
  }
}

const UuidForm = connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);

export default withTranslation()(UuidForm);
