import * as React from 'react';
import {useTranslation} from 'react-i18next';
import MainLayout from '../main';

const styles = require('./tools.module.scss');

interface Props {
  currentPage: string;
  children: any;
}

function Layout(props: Props) {
  const {currentPage, children} = props;
  const {t} = useTranslation();

  return (
    <MainLayout currentPage={currentPage}>
      <div className={styles.toolsPage}>
        <h1 className={styles.title}>{t(currentPage + '-title')}</h1>
        <div className={styles.tools}>
          {children}
        </div>
        <h2>{t(currentPage + '-about')}</h2>
        <p className={styles.desc} dangerouslySetInnerHTML={{__html: t(currentPage + '-desc')}}/>
        <h2>{t(currentPage + '-what-is')}</h2>
        <p className={styles.desc} dangerouslySetInnerHTML={{__html: t(currentPage + '-what-is-desc')}}/>
        <p className={styles.desc} dangerouslySetInnerHTML={{__html: t(currentPage + '-what-is-desc-p2')}}/>
        <h2>{t(currentPage + '-learn-more')}</h2>
        <p className={styles.desc} dangerouslySetInnerHTML={{__html: t(currentPage + '-what-is-desc-p3')}}/>
      </div>
    </MainLayout>
  );
}

export default Layout;
