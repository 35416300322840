class Analytics {
  public static gtag(type: string, action: string, data: any) {
    if (window.hasOwnProperty('gtag')) {
      // @ts-ignore
      return window.gtag.apply(null, arguments);
    }
  }
}

export default Analytics;
