import * as React from 'react';
import withI18next from '../../plugins/gatsby-plugin-i18next/withI18next';
import UuidForm from '../components/tools/uuid';
import Layout from '../layouts/tools';
import constants from '../utils/constants';

function Page() {
  return (
    <Layout currentPage={constants.PAGE_UUID}>
      <UuidForm/>
    </Layout>
  );
}

export default withI18next()(Page);
